import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Logo from './images/logo-mark.png';
import Drumroll from './images/drumroll.gif';
import Thumbsup from './images/thumbsup.gif';
import Dance from './images/dancing.gif';
import Smiley from './images/smiley-face.png';
import Dead1 from './images/dead1.gif';
import Dead2 from './images/dead2.gif';
import Dead3 from './images/dead3.gif';
import Dead4 from './images/dead4.gif';
import Dead5 from './images/dead5.gif';
import Dead6 from './images/dead6.gif';
import Dead7 from './images/dead7.gif';
import Dead8 from './images/dead8.gif';
import No1 from './images/no-sign.gif';
import No2 from './images/nope-2.gif';
import No3 from './images/nope-3.gif';
import No4 from './images/nope-4.gif';
import Rocket from './images/rocket.gif';
import LetsGo from './images/letsgo.gif';
import LogoTile from './images/logo-tile.png';
import Telegram from './images/telegram-logo.png';
import SiteLinks from './SiteLinks/SiteLinks.js';
import ComingSoon from './ComingSoon.js';
import Discord from './images/discord.svg';
import Reddit from './images/reddit.svg';
import Twitter from './images/x.svg';
import FB from './images/fb-logo.png';
import 'animate.css';

function formatNumber(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const Answer = () => {
  const randomNo = [No1, No2, No3, No4];
  const randomNum = Math.floor(Math.random() * 4);
  const priceApi =
    'https://api.coingecko.com/api/v3/simple/price?ids=ecash&vs_currencies=usd&include_24hr_change=true';
  const [currentPrice, setCurrentPrice] = useState([0, 0]);
  const [forkDayChange, setForkdayChange] = useState(0);
  const [rebrandDayChange, setRebrandDayChange] = useState(0);
  const [stakingChange, setStakingChange] = useState(0);
  const forkdayprice = 0.0000104172;
  const branddayprice = 0.0000311694;
  const stakingprice = 0.00002344;

  const updatePrice = () => {
    axios.get(priceApi).then((response) => {
      if (response.data) {
        setCurrentPrice([
          response.data.ecash.usd,
          response.data.ecash.usd_24h_change,
        ]);
        setForkdayChange(
          (
            ((response.data.ecash.usd - forkdayprice) / forkdayprice) *
            100
          ).toFixed(2)
        );
        setRebrandDayChange(
          (
            ((response.data.ecash.usd - branddayprice) / branddayprice) *
            100
          ).toFixed(2)
        );
        setStakingChange(
          (
            ((response.data.ecash.usd - stakingprice) / stakingprice) *
            100
          ).toFixed(2)
        );
      }
    });
  };

  useEffect(() => {
    updatePrice();
    const interval = setInterval(() => {
      updatePrice();
    }, 50000);
    return () => clearInterval(interval);
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <>
      {loading === true ? (
        <img src={Drumroll} alt="drumroll" className="drumroll" />
      ) : (
        <>
          <h2 className="no-way animate__animated animate__jackInTheBox">
            NO WAY!!
          </h2>
          <div className="answer animate__animated animate__fadeInUpBig">
            <div className="gif-ctn">
              <img src={Dance} alt="dance" className="dance" />
              <img className="nope" src={randomNo[randomNum]} alt="nope" />
              <img src={Dance} alt="dance" className="dance" />
            </div>

            <p className="answer-p">
              eCash is alive and well, and ready for our moon shot!
              <br />
              <br />
              Our community is growing daily and the tech keeps getting better.
              Are you ready to redefine wealth?
            </p>

            <a
              className="glow-on-hover"
              href="https://e.cash"
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </a>
            <div className="price-section">
              <div className="price-row">
                <p>Current Price</p>
                <div className="price">
                  1 bit (XEC) = ${currentPrice[0].toFixed(8)}
                  {/* <span
                    className="percentage-change"
                    style={
                      currentPrice[1] >= 0
                        ? null
                        : { color: 'rgb(239, 68, 68)' }
                    }
                  >
                    <div
                      className={currentPrice[1] >= 0 ? 'arrow-up2' : null}
                      style={
                        currentPrice[1] >= 0
                          ? { borderBottomColor: '#10b981' }
                          : { borderBottomColor: 'rgb(239, 68, 68)' }
                      }
                    />
                    {currentPrice[1].toFixed(2)}%
                  </span> */}
                </div>
              </div>

              <div className="price-row">
                <p>Price since Nov 15, 2020 (fork day)</p>
                <div className="price">
                  <span
                    className="percentage"
                    style={
                      forkDayChange >= 0
                        ? null
                        : { backgroundColor: 'rgb(239, 68, 68)' }
                    }
                  >
                    <div className={forkDayChange >= 0 ? 'arrow-up' : null} />
                    {formatNumber(forkDayChange)}%
                  </span>
                  {forkDayChange >= 0 ? (
                    <img src={Smiley} alt="Smiley" className="emoji" />
                  ) : null}
                </div>
              </div>
              <div className="price-row">
                <p>Price since Jul 1, 2021 (rebrand day)</p>
                <div className="price">
                  <span
                    className="percentage"
                    style={
                      rebrandDayChange >= 0
                        ? null
                        : { backgroundColor: 'rgb(239, 68, 68)' }
                    }
                  >
                    <div
                      className={rebrandDayChange >= 0 ? 'arrow-up' : null}
                    />
                    {formatNumber(rebrandDayChange)}%
                  </span>
                  {rebrandDayChange >= 0 ? (
                    <img src={Smiley} alt="Smiley" className="emoji" />
                  ) : null}
                </div>
              </div>

              <div className="price-row">
                <p>Price since Sept 7, 2023 (staking rewards code release)</p>
                <div className="price">
                  <span
                    className="percentage"
                    style={
                      stakingChange >= 0
                        ? null
                        : { backgroundColor: 'rgb(239, 68, 68)' }
                    }
                  >
                    <div className={stakingChange >= 0 ? 'arrow-up' : null} />
                    {formatNumber(stakingChange)}%
                  </span>
                  {stakingChange >= 0 ? (
                    <img src={Smiley} alt="Smiley" className="emoji" />
                  ) : null}
                </div>
              </div>
            </div>
            <img src={Thumbsup} alt="thumbsup" style={{ marginTop: '-40px' }} />
            <ComingSoon />
            <div className="rocket-ctn">
              <img src={LetsGo} alt="rocket" className="rocket-gif" />
              <img src={Rocket} alt="rocket" className="rocket-gif" />
              <img src={LetsGo} alt="rocket" className="rocket-gif" />
            </div>
            <h3>Join the discussion</h3>
            <p className="telegram-p">
              Jump in our social channels to chat with other eCash fans.
              <br />
              Dank memes guaranteed.
            </p>
            <div className="social-ctn">
              <a href="https://t.me/ecash" target="_blank" rel="noreferrer">
                <img className="telegram" src={Telegram} alt="telegram" />
              </a>
              <a
                href="https://discord.gg/BxUpjWpjtY"
                target="_blank"
                rel="noreferrer"
              >
                <img className="telegram" src={Discord} alt="telegram" />
              </a>
              <a
                href="https://www.reddit.com/r/ecash/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="telegram" src={Reddit} alt="telegram" />
              </a>
              <a
                href="https://twitter.com/ecashofficial"
                target="_blank"
                rel="noreferrer"
              >
                <img className="telegram" src={Twitter} alt="telegram" />
              </a>
              <a
                href="https://www.facebook.com/groups/ecashofficial"
                target="_blank"
                rel="noreferrer"
              >
                <img className="telegram" src={FB} alt="telegram" />
              </a>
            </div>
            <SiteLinks />
          </div>
        </>
      )}
    </>
  );
};

const App = () => {
  const [answer, showAnswer] = useState(false);
  const randomDead = [
    Dead1,
    Dead1,
    Dead2,
    Dead3,
    Dead4,
    Dead5,
    Dead6,
    Dead7,
    Dead8,
  ];
  const randomNum = Math.floor(Math.random() * 8);

  return (
    <div className="main-ctn" style={{ backgroundImage: `url(${LogoTile})` }}>
      <a className="banner" href="https://avalanche.cash/">
        Big things are coming to eCash. Click here for more info
      </a>
      <div className="wrapper">
        <div className="question-ctn animate__animated animate__fadeInUp">
          <img
            src={Logo}
            alt="ecash logo"
            className="logo animate__animated animate__flipInY"
          />
          <div className="question-text">
            Is eCash
            <div className="glitch" data-text="DEAD">
              DEAD
            </div>
            ?
          </div>
          {!answer && (
            <>
              <div
                className="glow-on-hover"
                onClick={() => {
                  showAnswer(!answer);
                }}
              >
                Click to reveal answer
              </div>
              <img
                src={randomDead[randomNum]}
                alt="is ecash dead"
                className="dead-gif"
              />
            </>
          )}

          {answer && <Answer />}
        </div>
      </div>
      {/* {answer && <SiteLinks />} */}
      <img src={Drumroll} alt="drumroll" className="drumroll2" />
      <img src={Dance} alt="drumroll" className="drumroll2" />
      <img src={No1} alt="drumroll" className="drumroll2" />
      <img src={No2} alt="drumroll" className="drumroll2" />
      <img src={No3} alt="drumroll" className="drumroll2" />
      <img src={No4} alt="drumroll" className="drumroll2" />
    </div>
  );
};

export default App;
